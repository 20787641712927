var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticStyle:{"padding":"10px"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"label":"Search","dense":"","outlined":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-dialog',{attrs:{"persistent":"","width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Effective date","dense":"","outlined":"","readonly":"","required":""},model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-date-picker',{attrs:{"scrollable":""},model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.loadReservationsModal}},[_vm._v("OK")])],1)],1)],1)],1)],1),_c('v-data-table',{attrs:{"item-key":"name","loading":_vm.loading,"loading-text":"Loading... Please wait","headers":_vm.headers,"items":_vm.reservations,"search":_vm.search,"custom-filter":_vm.customFilter},scopedSlots:_vm._u([{key:"item.student.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.student.name)+" "),_c('br'),_c('span',{staticStyle:{"color":"black","font-weight":"bold"}},[_vm._v(_vm._s(item.student.student_school.name))])]}},{key:"item.planned_trip.driver.name",fn:function(ref){
var item = ref.item;
return [(item.planned_trip_morning && item.planned_trip_morning.driver)?_c('span',[_vm._v(" "+_vm._s(item.planned_trip_morning.driver.name)+" ")]):_vm._e(),(item.planned_trip_evening && item.planned_trip_evening.driver)?_c('span',[_vm._v(_vm._s(item.planned_trip_evening.driver.name))]):_vm._e()]}},{key:"item.guardian_names",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.guardian_names.join(", "))+" ")]}},{key:"item.first_stop.name",fn:function(ref){
var item = ref.item;
return [(item.student_trip_morning)?_c('div',[_c('v-icon',{staticClass:"ml-2",attrs:{"small":"","color":"success","dark":""}},[_vm._v(" mdi-weather-sunset-up ")]),_c('a',{on:{"click":function($event){$event.stopPropagation();return _vm.displayStop(item.student_trip_morning.first_stop.id)}}},[_c('span',{attrs:{"small":""}},[_vm._v(" "+_vm._s(item.student_trip_morning.first_stop.name)+" ")])])],1):_vm._e(),(item.student_trip_evening)?_c('div',[_c('v-icon',{staticClass:"ml-2",attrs:{"small":"","color":"warning","dark":""}},[_vm._v(" mdi-weather-sunset-down ")]),_c('a',{on:{"click":function($event){$event.stopPropagation();return _vm.displayStop(item.student_trip_evening.first_stop.id)}}},[_c('span',{attrs:{"small":""}},[_vm._v(" "+_vm._s(item.student_trip_evening.first_stop.name))])])],1):_vm._e()]}},{key:"item.last_stop.name",fn:function(ref){
var item = ref.item;
return [(item.student_trip_morning)?_c('div',[_c('v-icon',{staticClass:"ml-2",attrs:{"small":"","color":"success","dark":""}},[_vm._v(" mdi-weather-sunset-up ")]),_c('a',{on:{"click":function($event){$event.stopPropagation();return _vm.displayStop(item.student_trip_morning.last_stop.id)}}},[_c('span',{attrs:{"small":""}},[_vm._v(" "+_vm._s(item.student_trip_morning.last_stop.name)+" ")])])],1):_vm._e(),(item.student_trip_evening)?_c('div',[_c('v-icon',{staticClass:"ml-2",attrs:{"small":"","color":"warning","dark":""}},[_vm._v(" mdi-weather-sunset-down ")]),_c('a',{on:{"click":function($event){$event.stopPropagation();return _vm.displayStop(item.student_trip_evening.last_stop.id)}}},[_c('span',{attrs:{"small":""}},[_vm._v(" "+_vm._s(item.student_trip_evening.last_stop.name)+" ")])])],1):_vm._e()]}},{key:"item.scan_time_morning",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(item.scan_time_morning, "H:m:s"))+" ")])]}},{key:"item.scan_time_evening",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(item.scan_time_evening, "H:m:s"))+" ")])]}},{key:"item.planned_trip.scan_time",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('small',[_c('b',[_vm._v("Trip:")]),_vm._v(" "+_vm._s(_vm.formatDate(item.riding_date, "DD/MM/YYYY")))])]),_c('div',[_c('small',[_c('b',[_vm._v("In:")]),_vm._v(" "+_vm._s(_vm.formatDate(item.scan_time_morning, "H:m:s")))])]),_c('div',[_c('small',[_c('b',[_vm._v("Out:")]),_vm._v(" "+_vm._s(_vm.formatDate(item.scan_time_evening, "H:m:s")))])])]}},{key:"item.planned_trip.planned_date",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(item.riding_date, "DD/MM/YYYY"))+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }