<template>
  <div>
    <div style="padding: 10px">
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            v-model="search"
            label="Search"
            dense
            outlined
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-dialog v-model="modal" persistent width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="selectedDate"
                label="Effective date"
                dense
                outlined
                readonly
                required
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="selectedDate" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal = false">Cancel</v-btn>
              <v-btn text color="primary" @click="loadReservationsModal"
                >OK</v-btn
              >
            </v-date-picker>
          </v-dialog>
        </v-col>
      </v-row>
    </div>

    <v-data-table
      item-key="name"
      :loading="loading"
      loading-text="Loading... Please wait"
      :headers="headers"
      :items="reservations"
      :search="search"
      :custom-filter="customFilter"
    >
      <!-- <template v-slot:top>
      <v-text-field v-model="search" label="Search" class="mx-4"></v-text-field>
    </template> -->

      <template v-slot:item.student.name="{ item }">
        {{ item.student.name }} <br />
        <span style="color: black; font-weight: bold">{{
          item.student.student_school.name
        }}</span>
      </template>

      <template v-slot:item.planned_trip.driver.name="{ item }">
        <!-- <template v-if="userType === 'guardians'"></template> -->
        <span
          v-if="item.planned_trip_morning && item.planned_trip_morning.driver"
        >
          {{ item.planned_trip_morning.driver.name }}
        </span>
        <span
          v-if="item.planned_trip_evening && item.planned_trip_evening.driver"
          >{{ item.planned_trip_evening.driver.name }}</span
        >
      </template>

      <template v-slot:item.guardian_names="{ item }">
        {{ item.guardian_names.join(", ") }}
      </template>

      <template v-slot:item.first_stop.name="{ item }">
        <div v-if="item.student_trip_morning">
          <v-icon small class="ml-2" color="success" dark>
            mdi-weather-sunset-up
          </v-icon>
          <a @click.stop="displayStop(item.student_trip_morning.first_stop.id)">
            <span small> {{ item.student_trip_morning.first_stop.name }} </span>
          </a>
        </div>
        <div v-if="item.student_trip_evening">
          <v-icon small class="ml-2" color="warning" dark>
            mdi-weather-sunset-down
          </v-icon>
          <a @click.stop="displayStop(item.student_trip_evening.first_stop.id)">
            <span small> {{ item.student_trip_evening.first_stop.name }}</span>
          </a>
        </div>
      </template>

      <template v-slot:item.last_stop.name="{ item }">
        <div v-if="item.student_trip_morning">
          <v-icon small class="ml-2" color="success" dark>
            mdi-weather-sunset-up
          </v-icon>
          <a @click.stop="displayStop(item.student_trip_morning.last_stop.id)">
            <span small> {{ item.student_trip_morning.last_stop.name }} </span>
          </a>
        </div>
        <div v-if="item.student_trip_evening">
          <v-icon small class="ml-2" color="warning" dark>
            mdi-weather-sunset-down
          </v-icon>
          <a @click.stop="displayStop(item.student_trip_evening.last_stop.id)">
            <span small> {{ item.student_trip_evening.last_stop.name }} </span>
          </a>
        </div>
      </template>
      <!-- 
      <template v-slot:item.planned_trip.route.name="{ item }">
        <div style="margin: 5px 0px">
          <div style="margin-top: 10px" v-if="item.planned_trip_morning">
            <v-icon
              small
              class="ml-2"
              :color="getTimeColor(item.planned_trip_morning.route.is_morning)"
              dark
            >
              {{ getTime(item.planned_trip_morning.route.is_morning) }}</v-icon
            >
            {{ item.planned_trip_morning.route.name }}
          </div>
          <div style="margin-top: 10px" v-if="item.planned_trip_evening">
            <v-icon
              small
              class="ml-2"
              :color="getTimeColor(item.planned_trip_evening.route.is_morning)"
              dark
            >
              {{ getTime(item.planned_trip_evening.route.is_morning) }}</v-icon
            >
            {{ item.planned_trip_evening.route.name }}
          </div>
        </div>
      </template> -->

      <template v-slot:item.scan_time_morning="{ item }">
        <div>
          {{ formatDate(item.scan_time_morning, "H:m:s") }}
        </div>
      </template>
      <template v-slot:item.scan_time_evening="{ item }">
        <div>
          {{ formatDate(item.scan_time_evening, "H:m:s") }}
        </div>
      </template>

      <template v-slot:item.planned_trip.scan_time="{ item }">
        <div>
          <small
            ><b>Trip:</b>
            {{ formatDate(item.riding_date, "DD/MM/YYYY") }}</small
          >
        </div>
        <div>
          <small
            ><b>In:</b> {{ formatDate(item.scan_time_morning, "H:m:s") }}</small
          >
        </div>
        <div>
          <small
            ><b>Out:</b>
            {{ formatDate(item.scan_time_evening, "H:m:s") }}</small
          >
        </div>
      </template>
      <template v-slot:item.planned_trip.planned_date="{ item }">
        <div>
          {{ formatDate(item.riding_date, "DD/MM/YYYY") }}
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
const userRoleEvent = "userRole";
import moment from "moment";
export default {
  props: {
    reservations: Array,
    showCancel: Boolean,
    loading: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      modal: false,
      selectedDate: null,
      search: "",
      role: null,
      headers: [],
      advanced_headers: [
        { text: "ID", value: "id", align: "start", filterable: false },
        { text: "Student", value: "student.name" },
        { text: "Driver Name", value: "planned_trip.driver.name" },
        { text: "Guardian Name", value: "guardian_names" },
        // { text: "School", value: "school.name" },
        // { text: "Driver", value: "planned_trip.driver.name" },
        // { text: "Route", value: "planned_trip.route.name" },
        { text: "From", value: "first_stop.name" },
        { text: "To", value: "last_stop.name" },
        { text: "Check In", value: "scan_time_morning" },
        { text: "Check Out", value: "scan_time_evening" },
        { text: "Time of trip", value: "planned_trip.planned_date" },
        // { text: "Details", value: "planned_trip.scan_time" },
      ],
      admin_advanced_headers: [
        { text: "ID", value: "id", align: "start", filterable: false },
        { text: "Student", value: "student.name" },
        { text: "Driver", value: "planned_trip.driver.name" },
        // { text: "Route", value: "planned_trip.route.name" },
        { text: "From", value: "first_stop.name" },
        { text: "To", value: "last_stop.name" },

        { text: "Time of trip", value: "planned_trip.planned_date" },
      ],
      simple_headers: [
        { text: "ID", value: "id", align: "start", filterable: false },
        { text: "Student", value: "student.name" },
        { text: "Driver", value: "planned_trip.driver.name" },
        { text: "From", value: "first_stop.name" },
        { text: "To", value: "last_stop.name" },
        { text: "Trip Time", value: "planned_trip.route.name" },
        { text: "Time of trip", value: "planned_trip.planned_date" },
      ],
    };
  },
  mounted() {
    this.role = localStorage.getItem(userRoleEvent);
    this.headers =
      this.mode === "advanced"
        ? this.role === "admin"
          ? this.admin_advanced_headers
          : this.advanced_headers
        : this.simple_headers;

    if (this.showCancel) {
      this.headers.push({
        text: "Cancel",
        value: "actions",
        sortable: false,
      });
    }
  },
  methods: {
    displayRoute(route_id) {
      this.$router.push({
        name: "view-route",
        params: { route_id: route_id },
      });
    },
    displayStudent(student_id) {
      this.$router.push({
        name: "view-student",
        params: { user_id: student_id },
      });
    },
    displayDriver(driver_id) {
      this.$router.push({
        name: "view-driver",
        params: { user_id: driver_id },
      });
    },
    getTimeColor(is_morning) {
      return is_morning ? "success" : "warning";
    },
    // getTime(is_morning) {
    //   return is_morning ? "Morning" : "Afternoon";
    // },
    getTime(is_morning) {
      return is_morning ? "mdi-weather-sunset-up" : "mdi-weather-sunset-down";
    },
    displayStop(stop_id) {
      this.$router.push({
        name: "view-stop",
        params: { stop_id: stop_id },
      });
    },
    cancelReservation(reservation, index) {
      this.$emit("cancel-reservation", reservation, index);
    },
    formatDate(date, format) {
      if (date && typeof date === "string") {
        // Check if the date is in a time-only format (HH:mm:ss)
        if (/^\d{2}:\d{2}:\d{2}$/.test(date)) {
          // Convert to ISO 8601 by adding a dummy date
          date = `1970-01-01T${date}`;
        } else if (!moment(date, moment.ISO_8601, true).isValid()) {
          // Handle other invalid formats (if any)
          console.warn("Invalid date format:", date);
          return "";
        }
      }
      // Return the formatted date using Moment.js
      return moment(date).format(format);
    },
    loadReservationsModal() {
      this.modal = false;
      this.loadReservations();
    },
    loadReservations() {
      this.isLoading = true;
      this.reservations = [];
      var request = {
        searchParams: {
          riding_date: this.selectedDate,
        },
        orderBy: {
          field: "id",
          direction: "desc",
        },
        limit: "",
      };
      axios
        .post(`/planned-trips/all-attendance`, request)
        .then((response) => {
          console.log(response);
          // this.activeReservations = response.data.active;
          // this.rideReservations = response.data.ride;
          this.reservations = response.data.completed;
          // this.missedReservations = response.data.missed;

          // this.displayedActiveReservations = response.data.active;
          // this.displayedRideReservations = response.data.ride;
          this.reservations = response.data.completed;
          // this.displayedMissedReservations = response.data.missed;
        })
        .catch((error) => {
          this.$notify({
            title: "Error",
            text: "Error while retrieving reservations",
            type: "error",
          });
          console.log(error);
          auth.checkError(
            error.response.data.message,
            this.$router,
            this.$swal
          );
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    customFilter(value, search, item) {
      const studentName = item.student?.name?.toLowerCase() || "";
      const morningDriverName =
        item.planned_trip_morning?.driver?.name?.toLowerCase() || "";
      const eveningDriverName =
        item.planned_trip_evening?.driver?.name?.toLowerCase() || "";
      const schoolName = item.student?.student_school?.name.toLowerCase() || "";
      // const guardianNames = item.guardian_names.join(", ").toLowerCase() || "";

      return (
        studentName.includes(search.toLowerCase()) ||
        schoolName.includes(search.toLowerCase()) ||
        morningDriverName.includes(search.toLowerCase()) ||
        eveningDriverName.includes(search.toLowerCase())
      );
    },
  },
  //   computed: {
  //   filteredReservations() {
  //     const searchTerm = this.search.toLowerCase();

  //     return this.reservations.filter((item) => {
  //       const studentName = item.student.name.toLowerCase();
  //       const schoolName = item.student.student_school.name.toLowerCase();
  //       const morningDriver = item.planned_trip_morning?.driver?.name.toLowerCase() || '';
  //       const eveningDriver = item.planned_trip_evening?.driver?.name.toLowerCase() || '';
  //       return (
  //         studentName.includes(searchTerm) ||
  //         schoolName.includes(searchTerm) ||
  //         morningDriver.includes(searchTerm) ||
  //         eveningDriver.includes(searchTerm)
  //       );
  //     });
  //   },
  // }
};
</script>
